import './App.css';
import {BrowserRouter as Router,Route,Routes} from "react-router-dom"
import Dashboard from './appScreens/Dashboard';
const App=()=>{
  return (
    <Router>
     <Routes>
      <Route element={<Dashboard/>} path={"/"} />
</Routes>
</Router>
)
}
export default App;
