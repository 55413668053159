import React from "react";
import Navbar from "../Navbar";
import SliderComp from "../Slider";
import Aboutus from "../Aboutus";
import VideoPlayer from "../VideoPlayer";
import ContactUs from "../Contactus";
import SmokeEffect from "../SmokeEffect";
import styles from "./index.module.css";


const Dashboard = () => {
  return (
    <div className={styles.mainContainer}>
      <Navbar />
      <SliderComp />
      <SmokeEffect />
      {/* <Aboutus/> */}
      {/* <VideoPlayer/> */}
      <ContactUs />
    </div>
  );
};

export default Dashboard;
