import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import styles from "./Contactus.module.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const ContactUs = () => {
  return (
    <div className={styles.parentContainer}>
      {/* LEFT SIDE */}
      <div className={styles.container}>
        <div style={{ fontSize: 26, fontWeight: "bold" }}>Contact us</div>
        {/* <div style={{ fontSize: 16, marginTop: 16 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </div> */}
        <div style={{ marginTop: 16 }}>
          <div className={styles.iconText}>
            <EmailIcon fontSize="large" />
            <div className={styles.leftText}>rajeev@experientialtales.com</div>
          </div>

          <div className={styles.iconText}>
            <PhoneIcon fontSize="large" />
            <div className={styles.leftText}>+971 55 154 0785</div>
          </div>

          <div className={styles.iconText}>
            <LocationOnIcon fontSize="large" />
            <div className={styles.leftText}>Oud Metha - Dubai,UAE</div>
          </div>
        </div>
      </div>
      {/* LEFT SIDE END */}

      {/* RIGHT SIDE*/}
      {/* <div className={styles.container}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.inputContainer}>
            <div>First Name</div>
            <div>
              <input className={styles.inputFeild} />
            </div>
          </div>

          <div className={styles.inputContainer}>
            <div>Last Name</div>
            <div>
              <input className={styles.inputFeild} />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.inputContainer}>
            <div>Email</div>
            <div>
              <input className={styles.inputFeild} />
            </div>
          </div>

          <div className={styles.inputContainer}>
            <div>Phone number</div>
            <div>
              <input className={styles.inputFeild} />
            </div>
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <div>
            <div>Choose a topic</div>
            <div style={{ width: "100%" }}>
              <input className={styles.inputFeild} />
            </div>
          </div>
        </div>

        <div>
          <div>Which best describes you?</div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value="first"
                  control={<Radio />}
                  label="First Choice"
                />
                <FormControlLabel
                  value="second"
                  control={<Radio />}
                  label="Second Choice"
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value="third"
                  control={<Radio />}
                  label="Third Choice"
                />
                <FormControlLabel
                  value="fourth"
                  control={<Radio />}
                  label="Fourth Choice"
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value="fifth"
                  control={<Radio />}
                  label="Fifth Choice"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="other"
                />
              </div>
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div>Message</div>
            <div style={{ width: "100%" }}>
              <input className={styles.messageFeild} />
            </div>
          </div>
        </div>

        <button className={styles.btn}>SUBMIT</button>
      </div> */}
    </div>
  );
};

export default ContactUs;
