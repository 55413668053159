import React from "react";
import styled, { keyframes } from "styled-components";
import { useRef, useEffect } from "react";
import "./styles.css";

const SmokeEffect = () => {
  const videoRef = useRef();

  const handleMouse = () => {
    if (videoRef.current?.paused) {
      videoRef.current.play();
    }
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "black",
        width: "400",
        textAlign: "center",
      }}
    >
      <video
        ref={videoRef}   
        autoPlay
        onMouseEnter={handleMouse}
        onMouseLeave={handleMouse}
      >
        <source src="images/smokeVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default SmokeEffect;
